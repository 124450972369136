/** EXECUTION AFTER FULL PAGE LOAD */
const readyStateCheckInterval = setInterval(() => {
    if (document.readyState === 'complete') {
        clearInterval(readyStateCheckInterval);

        // hide banner only in Safari browsers on iOS devices
        if (!(navigator.userAgent.match(/Safari/) && !navigator.userAgent.match(/Chrome|Chromium/)
            && navigator.userAgent.match(/Mobile/))) {
            /* eslint-disable no-undef */
            document.addEventListener('smartbanner.view', () => {
                const bodyEl = document.querySelector('body');
                bodyEl.classList.add('magenta-page__appbanner-visible');
            });
            document.addEventListener('smartbanner.exit', () => {
                const bodyEl = document.querySelector('body');
                bodyEl.classList.remove('magenta-page__appbanner-visible');
            });
            smartbanner.publish();
            /* eslint-disable no-undef */
        }
    }
}, 10);
